/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SearchBox from "../components/contentElements/searchBox";
import H1 from "../components/htmlElements/h1";

/**
 * https://www.gatsbyjs.com/docs/how-to/routing/adding-markdown-pages/#frontmatter-for-metadata-in-markdown-files
 *
 *
 * Use this tool to transform HTML into markdown:
 * https://markdown.co/tool/html-to-markdown
 */

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { pageContent, tables } = data; // data.pageContent holds your page data
  const { frontmatter, html } = pageContent;

  // We need to replace some H2/H3 tags with our custom H2/H3 tag (includes some nice styling)
  let newHtml = html
    // eslint-disable-next-line prettier/prettier
    .replace(/<h2>/gi, '<h2 class="text-2xl font-bold font-heading pb-5 pt-10">')
    .replace(/<h3>/gi, '<h3 class="text-xl font-bold font-heading pb-5 pt-5">');

  // Now replace custom placeholder with the table content from individual MD files
  if (tables) {
    for (let i = 0; i < tables.edges.length; i++) {
      const tableTitle = `[${tables.edges[i].node.frontmatter.title}]`;
      const tableHtml = tables.edges[i].node.html;
      newHtml = newHtml.replace(tableTitle, tableHtml);
    }
  }

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1 innerContent={frontmatter.title} additionalClasses="pb-5" />

          <div
            className="markdown-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: newHtml }}
          />
        </div>

        <div className="col-span-12 md:col-span-5">
          <SearchBox />
          <br />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    pageContent: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
    tables: allMarkdownRemark(
      filter: { frontmatter: { title: { glob: "table-*" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
